import { TRegionKey } from '@source/interface';
import { getStaticCDN, getStaticCDNFixedDimension } from '@design-system/utils/utils';
import { MetaDataProps, MetaDataPropsWithLang } from '@source/pages/Home/components/HeadMeta/HeadMetaProps';

const IMAGE_SIZE: { width: string; height: string } = {
  width: '1200',
  height: '630',
};

const MY_META_DATA: MetaDataProps | MetaDataPropsWithLang = {
  index: {
    title: `The Best Place to Buy & Sell Cars | Carro`,
    metaTitle: 'The Best Place to Buy & Sell Cars | Carro',
    description:
      'Buy used car, sell used car online easily in Malaysia at Carro. Beli & Jual kereta terpakai dengan mudah & selamat. Tempat jual kereta di Malaysia terpercaya.',
    image: getStaticCDN('/assets/shared/meta-thumb/Carro_Metatag_1200x628.png'),
    ...IMAGE_SIZE,
  },
  home: {
    en: {
      title: 'Carro - Best Online Platform to Buy & Sell Used Cars',
      metaTitle: 'Carro - Best Online Platform to Buy & Sell Used Cars',
      description:
        "Carro is Malaysia's No.1 online car marketplace to buy & sell used cars with transparent pricing, comprehensive inspections, and nationwide services.",
      image: getStaticCDN('/assets/shared/meta-thumb/Carro_Metatag_1200x628.png'),
      ...IMAGE_SIZE,
    },
    'zh-MY': {
      title: '轻松买卖二手车 | 寻找官方认证二手车',
      metaTitle: '轻松买卖二手车 | 寻找官方认证二手车',
      description: '无论您在马来西亚何处都可以透过线上与Carro买卖二手车。浏览Carro官方认证汽车',
      image: getStaticCDN('/assets/shared/meta-thumb/Carro_Metatag_1200x628.png'),
      ...IMAGE_SIZE,
    },
    ms: {
      title: 'Jual & beli kereta terpakai dengan senang | Cari kereta terpakai',
      metaTitle: 'Jual & beli kereta terpakai dengan senang | Cari kereta terpakai',
      description:
        'Jual & beli kereta terpakai dengan Carro secara dalam talian di mana sahaja di Malaysia. Layari laman web kami untuk melihat semua kereta terpakai Carro Certified.',
      image: getStaticCDN('/assets/shared/meta-thumb/Carro_Metatag_1200x628.png'),
      ...IMAGE_SIZE,
    },
  },
  sell: {
    en: {
      title: 'Sell Your Used Car with Carro - Fast & Best Price',
      metaTitle: 'Sell Your Used Car with Carro - Fast & Best Price',
      description:
        'Sell your used car with Carro Malaysia. Get a free inspection, our best price and enjoy quick payment. Trusted by satisfied customers.',
      image: getStaticCDNFixedDimension('/assets/my/meta-thumb/Carro_Metatag_1200x628.png', 1200, 628),
      ...IMAGE_SIZE,
    },
    ms: {
      title: 'Jual Kereta Terpakai dengan Carro - Cepat & Harga Terbaik',
      metaTitle: 'Jual Kereta Terpakai dengan Carro - Cepat & Harga Terbaik',
      description:
        'Jual kereta terpakai anda dengan Carro Malaysia dengan harga terbaik. Tempah pemeriksaan percuma, dapatkan tawaran terbaik kami dan bayaran segera.',
      image: getStaticCDNFixedDimension('/assets/my/meta-thumb/Carro_Metatag_1200x628.png', 1200, 628),
      ...IMAGE_SIZE,
    }
  },
  certified: {
    title: 'Carro Certified',
    metaTitle: 'Carro Certified',
    description:
      'Buying with Carro is easy & hassle free. Enjoy doorstep delivery of Carro Certified Cars with 5-Day Money Back Guarantee & 1-Year Extended Warranty',
    image: getStaticCDN('/my/img/Carro-seo-v2.jpg'),
    ...IMAGE_SIZE,
  },
  userProfileIndex: {
    en: {
      title: 'My Profile | Carro',
      metaTitle: 'My Profile | Carro',
      description: 'View personal information, browsing history and more. ',
      image: getStaticCDN('/assets/shared/meta-thumb/Carro_Metatag_1200x628.png'),
      ...IMAGE_SIZE,
    },
    ms: {
      title: 'Profil Saya | Carro',
      metaTitle: 'Profil Saya | Carro',
      description: 'Lihat maklumat peribadi, sejarah penyemakan imbas dan banyak lagi.',
      image: getStaticCDN('/assets/shared/meta-thumb/Carro_Metatag_1200x628.png'),
      ...IMAGE_SIZE,
    },
    'zh-MY': {
      title: '我的简介 | Carro',
      metaTitle: '我的简介 | Carro',
      description: '查看个人信息、浏览历史等。',
      image: getStaticCDN('/assets/shared/meta-thumb/Carro_Metatag_1200x628.png'),
      ...IMAGE_SIZE,
    },
  },
  userProfilePersonalInfo: {
    en: {
      title: 'Edit Personal Info | Carro',
      metaTitle: 'Edit Personal Info | Carro',
      description: 'Update personal information and contact details. ',
      image: getStaticCDN('/assets/shared/meta-thumb/Carro_Metatag_1200x628.png'),
      ...IMAGE_SIZE,
    },
    ms: {
      title: 'Edit Maklumat Peribadi | Carro',
      metaTitle: 'Edit Maklumat Peribadi | Carro',
      description: 'Kemas kini maklumat peribadi dan butiran hubungan.',
      image: getStaticCDN('/assets/shared/meta-thumb/Carro_Metatag_1200x628.png'),
      ...IMAGE_SIZE,
    },
    'zh-MY': {
      title: '编辑个人信息 | Carro',
      metaTitle: '编辑个人信息 | Carro',
      description: '更新个人信息和联系方式。',
      image: getStaticCDN('/assets/shared/meta-thumb/Carro_Metatag_1200x628.png'),
      ...IMAGE_SIZE,
    },
  },
  ccpoIndex: {
    en: {
      title: 'Every Carro Certified Car Comes With 5 Guarantees',
      metaTitle: 'Every Carro Certified Car Comes With 5 Guarantees',
      description:
        'Carro Certified includes a 12-month engine & gearbox warranty, a 5-day money back guarantee, no major accidents, fire & flood damage and more.',
      image: getStaticCDN('/assets/shared/meta-thumb/Carro_Metatag_1200x628.png'),
      ...IMAGE_SIZE,
    },
    ms: {
      title: 'Setiap Kereta Carro Certified Datang Dengan 5 Jaminan',
      metaTitle: 'Setiap Kereta Carro Certified Datang Dengan 5 Jaminan',
      description:
        'Carro Certified datang dengan waranti 12 bulan enjin & kotak gear, jaminan pulangan wang dalam 5 hari dan lebih lagi.',
      image: getStaticCDN('/assets/shared/meta-thumb/Carro_Metatag_1200x628.png'),
      ...IMAGE_SIZE,
    },
    'zh-MY': {
      title: 'Carro Certified',
      metaTitle: 'Carro Certified',
      description:
        'Buying with Carro is easy & hassle free. Enjoy doorstep delivery of Carro Certified Cars with 5-Day Money Back Guarantee & 1-Year Extended Warranty. ',
      image: getStaticCDN('/assets/shared/meta-thumb/Carro_Metatag_1200x628.png'),
      ...IMAGE_SIZE,
    },
  },
  locateUsIndex: {
    en: {
      title: 'Find Carro Malaysia Locations',
      metaTitle: 'Find Carro Malaysia Locations',
      description:
        'Find and locate a Carro branch near you. Drop by for a free inspection, service or test drive a Carro Certified used car that is As Good As New.',
      image: getStaticCDN('/assets/shared/meta-thumb/Carro_Metatag_1200x628.png'),
      ...IMAGE_SIZE,
    },
    ms: {
      title: 'Lokasi Terbaik untuk Beli & Jual Kereta',
      metaTitle: 'Lokasi Terbaik untuk Beli & Jual Kereta',
      description:
        'Cari cawangan Carro yang terdekat. Datang untuk servis, pemeriksaan kereta percuma atau pandu uji kereta Carro Certified Sebaik Yang Baru.',
      image: getStaticCDN('/assets/shared/meta-thumb/Carro_Metatag_1200x628.png'),
      ...IMAGE_SIZE,
    },
    'zh-MY': {
      title: 'The Best Place to Buy & Sell Cars | Carro',
      metaTitle: 'The Best Place to Buy & Sell Cars | Carro',
      description:
        'Buy used cars, sell used cars online easily in Malaysia at Carro. Buy & sell used cars easily & safely. A trusted place to sell cars in Malaysia.',
      image: getStaticCDN('/assets/shared/meta-thumb/Carro_Metatag_1200x628.png'),
      ...IMAGE_SIZE,
    },
  },
  expiredOTT: {
    en: {
      title: 'Check In Failed',
      description:
        'Oops! Your Check-in Has Failed because your link has expired. Please find a Product Genius near you to check-in again!',
    },
    ms: {
      title: 'Check In Failed',
      description:
        'Oops! Your Check-in Has Failed because your link has expired. Please find a Product Genius near you to check-in again!',
    },
    'zh-MY': {
      title: 'Check In Failed',
      description:
        'Oops! Your Check-in Has Failed because your link has expired. Please find a Product Genius near you to check-in again!',
    },
  },
  viewingHistoryIndex: {
    en: {
      title: 'Viewing History | Carro',
      metaTitle: 'Viewing History | Carro',
      description: 'View personal information, browsing history and more. ',
      image: getStaticCDN('/assets/shared/meta-thumb/Carro_Metatag_1200x628.png'),
      ...IMAGE_SIZE,
    },
    ms: {
      title: 'Sejarah Pencarian | Carro',
      metaTitle: 'Sejarah Pencarian | Carro',
      description: 'Lihat maklumat peribadi, sejarah penyemakan imbas dan banyak lagi.        ',
      image: getStaticCDN('/assets/shared/meta-thumb/Carro_Metatag_1200x628.png'),
      ...IMAGE_SIZE,
    },
    'zh-MY': {
      title: '浏览纪录 | Carro',
      metaTitle: '浏览纪录 | Carro',
      description: '查看个人信息、浏览历史等。',
      image: getStaticCDN('/assets/shared/meta-thumb/Carro_Metatag_1200x628.png'),
      ...IMAGE_SIZE,
    },
  },
  promotionNFCIndex: {
    en: {
      title: "Here's how we make it as good as new | Carro",
      metaTitle: "Here's how we make it as good as new | Carro",
      description: 'From exterior to interior, every Carro Certified car is as good as new ',
      image: getStaticCDN('/assets/shared/meta-thumb/Carro_Metatag_1200x628.png'),
      ...IMAGE_SIZE,
    },
    ms: {
      title: 'Cara kami ubah suai agar tampak seperti baru | Carro',
      metaTitle: 'Cara kami ubah suai agar tampak seperti baru | Carro',
      description: 'Dari bahagian luar ke dalam, setiap kereta Carro Certified berfungsi seperti baru',
      image: getStaticCDN('/assets/shared/meta-thumb/Carro_Metatag_1200x628.png'),
      ...IMAGE_SIZE,
    },
    'zh-MY': {
      title: 'H以下就是我们确保 as good as new 的应对方案',
      metaTitle: 'H以下就是我们确保 as good as new 的应对方案',
      description: '无论是车子的外观还是内部，每一部Carro认证的车子都是 as good as new',
      image: getStaticCDN('/assets/shared/meta-thumb/Carro_Metatag_1200x628.png'),
      ...IMAGE_SIZE,
    },
  },
  error: {
    en: {
      title: 'Error | Carro',
      metaTitle: 'Error | Carro',
    },
    ms: {
      title: 'Ralat | Carro',
      metaTitle: 'Ralat | Carro',
    },
    'zh-MY': {
      title: '错误 | Carro',
      metaTitle: '错误 | Carro',
    },
  },
  workshop: {
    en: {
      title: 'Carro Care - Comprehensive Car Service & Repair in Malaysia',
      metaTitle: 'Carro Care - Comprehensive Car Service & Repair in Malaysia',
      description:
        'Enjoy professional comprehensive maintenance & repair services at Carro Care. Reach out to us today and service your car with Carro.',
      image: getStaticCDNFixedDimension('assets/my/meta-thumb/workshop_Metatag_1200x628.jpg', 1200, 628),
      ...IMAGE_SIZE,
    },
    ms: {
      title: 'Carro Care - Servis & Pembaikan Kereta Meyeluruh di Malaysia',
      metaTitle: 'Carro Care - Servis & Pembaikan Kereta Meyeluruh di Malaysia',
      description:
        'Nikmati perkhidmatan servis komprehensif dan pembaikan yang profesional dengan Carro Care. Hubungi kami hari ini dan servis kereta anda dengan Carro.',
      image: getStaticCDNFixedDimension('assets/my/meta-thumb/workshop_Metatag_1200x628.jpg', 1200, 628),
      ...IMAGE_SIZE,
    }
  },
  termsAndConditions: {
    title: `Carro Malaysia Terms of Use`,
    metaTitle: 'Carro Malaysia Terms of Use',
    description:
      "Read Carro Malaysia's Terms of Use outlining user agreements, privacy policies, and service conditions for buying and selling vehicles.",
    image: getStaticCDN('/assets/shared/meta-thumb/Carro_Metatag_1200x628.png'),
    ...IMAGE_SIZE,
  }
};

const SG_META_DATA: MetaDataProps | MetaDataPropsWithLang = {
  index: {
    en: {
      title: 'The Best Place to Buy and Sell Second-Hand & Used Cars in Singapore',
      metaTitle: 'The Best Place to Buy and Sell Second-Hand & Used Cars in Singapore',
      description:
        'Singapore’s largest AI-driven used car marketplace providing you with transparency and quality assurance when buying and selling a second-hand car.',
      image: getStaticCDN('/assets/shared/meta-thumb/Carro_Metatag_1200x628.png'),
      ...IMAGE_SIZE,
    },
    'ms-SG': {
      title: 'Tempat terbaik untuk membeli dan menjual kereta terpakai di Singapura',
      metaTitle: 'Tempat terbaik untuk membeli dan menjual kereta terpakai di Singapura',
      description:
        'Pasaran kereta terpakai AI terbesar di Singapura yang memberikan anda ketelusan dan jaminan kualiti apabila membeli dan menjual kereta terpakai.',
      image: getStaticCDN('/assets/shared/meta-thumb/Carro_Metatag_1200x628.png'),
      ...IMAGE_SIZE,
    },
  },
  home: {
    en: {
      title: 'The Best Place to Buy and Sell Second-Hand & Used Cars in Singapore',
      metaTitle: 'The Best Place to Buy and Sell Second-Hand & Used Cars in Singapore',
      description:
        'Singapore’s largest AI-driven used car marketplace providing you with transparency and quality assurance when buying and selling a second-hand car.',
      image: getStaticCDN('/assets/shared/meta-thumb/Carro_Metatag_1200x628.png'),
      ...IMAGE_SIZE,
    },
    'ms-SG': {
      title: 'Tempat terbaik untuk membeli dan menjual kereta terpakai di Singapura',
      metaTitle: 'Tempat terbaik untuk membeli dan menjual kereta terpakai di Singapura',
      description:
        'Pasaran kereta terpakai AI terbesar di Singapura yang memberikan anda ketelusan dan jaminan kualiti apabila membeli dan menjual kereta terpakai.',
      image: getStaticCDN('/assets/shared/meta-thumb/Carro_Metatag_1200x628.png'),
      ...IMAGE_SIZE,
    },
  },
  sell: {
    en: {
      title: 'Sell Your Car for Highest Price | Sell Car Singapore',
      metaTitle: 'Sell Car For More | Carro Car Mart Singapore',
      description:
        'Sell your car in Singapore at the highest price on the Carro car selling platform. Fair & Expert Valuation. NO LOWBALL OFFERS. Get a response in just 15 mins.',
      image: getStaticCDN('/assets/shared/meta-thumb/Carro_Metatag_1200x628.png'),
      ...IMAGE_SIZE,
    },
    'ms-SG': {
      title: 'Menjual kereta untuk lebih | Carro Car Mart Singapura',
      metaTitle: 'Menjual kereta untuk lebih | Carro Car Mart Singapura',
      description:
        'Jual kereta anda di Singapura dengan harga tertinggi di platform menjual kereta Carro. Penilaian adil & pakar. Tiada tawaran bola rendah. Dapatkan respons dalam masa 15 minit.',
      image: getStaticCDN('/assets/shared/meta-thumb/Carro_Metatag_1200x628.png'),
      ...IMAGE_SIZE,
    },
  },
  express: {
    title: 'Sell Car In 1 hour | Carro Used Car Marketplace',
    metaTitle: 'Sell Car For Cash | Carro Car Mart Singapore',
    description:
      'Get a value for your used car within 1 hour and enjoy full paperwork services to for the fastest car selling experience.',
    image: 'https://carro.sg/assets/img/meta_express_new_100619.jpg',
    ...IMAGE_SIZE,
  },
  coe_renewal: {
    title: 'COE Renewal Loan | Approved in 24 hours | Carro Singapore',
    metaTitle: 'Renew COE Car Loan Online | Carro Singapore',
    description:
      'Enjoy a low-interest COE renewal loan for your car when you do it online with Carro! Get your loan approved in just 1 day & we will handle all the paperwork. Learn more now.',
    image: 'https://carro.sg/assets/img/meta_coe_loan_100619.jpg',
    ...IMAGE_SIZE,
  },
  usedCarLoan: {
    title: 'Get a Used Car Loan Online | Approved in 24 hours | Carro Singapore',
    metaTitle: 'Lowest Used Car Loans | Carro Car Mart Singapore',
    description:
      'Finance your used car with Carro’s flexible, low interest car loan options. Get the loan approved for your second-hand car in just 1 day! Find out more now.',
    image: 'https://carro.sg/assets/img/meta_loan_new_100619.jpg',
    ...IMAGE_SIZE,
  },
  about: {
    title: "About Us | SEA's Largest Car Marketplace | Carro Singapore",
    metaTitle: 'About Us - Automotive Marketplace in Singapore | Carro',
    description:
      'As an automotive marketplace in Singapore, Carro makes selling, buying & subscribing to cars online a worry-free & transparent experience for Singaporeans. Learn more about us here.',
    image: 'https://carro.sg/assets/img/meta_about_100619.jpg',
    ...IMAGE_SIZE,
  },
  faq: {
    title: 'Frequently Asked Questions | Carro Singapore',
    metaTitle: 'Most Commonly Asked Questions | Carro Car Mart Singapore',
    description:
      'Check out the frequently asked questions about selling & buying cars on Carro. Get more information about our services now.',
    image: 'https://carro.sg/assets/img/meta_about_100619.jpg',
    ...IMAGE_SIZE,
  },
  team: {
    title: 'Join Us | Career Opportunities at Carro Singapore',
    metaTitle: 'Career Opportunities - Join Us | Carro Singapore',
    description:
      'Want to experience working in a start-up environment? Check out our current job openings here! Cant find what youre looking for? Send us your resume now!',
    image: 'https://carro.sg/assets/img/meta_team_new_100619.jpg',
    ...IMAGE_SIZE,
  },
  termsAndCondition: {
    title: 'Terms & Conditions | Carro Singapore',
    metaTitle: 'Privacy Policy | Carro Car Mart Singapore',
    description:
      'Carro respects your privacy, and is committed to protect it. Check out our terms & conditions and privacy policy here. Learn more now.',
    image: 'https://carro.sg/assets/img/meta_terms_100619.jpg',
    ...IMAGE_SIZE,
  },
  contact: {
    en: {
      title: 'Contact Us | Carro Singapore',
      metaTitle: 'List Your Car For Sale | Carro Car Mart Singapore',
      description:
        'Do you have any enquiries? Pay us a visit or contact us via our hotline or email! We are open everyday except public holidays. Click here now.',
      image: 'https://carro.sg/assets/img/meta_contact_100619.jpg',
      ...IMAGE_SIZE,
    },
    'ms-SG': {
      title: 'Hubungi Kami | Carro Singapura',
      metaTitle: 'Hubungi Kami | Carro Singapura',
      description:
        'Adakah anda mempunyai sembarangan pertanyaan? lawati atau hubungi kami melalui hotline atau e-mel kami! Kami dibuka setiap hari kecuali cuti umum. Klik di sini sekarang.',
      image: 'https://carro.sg/assets/img/meta_contact_100619.jpg',
      ...IMAGE_SIZE,
    },
  },
  workshop: {
    title: 'Car Servicing & Car Repairs | Car Workshop | Carro Singapore',
    metaTitle: 'Transparent & Trustworthy Car Repairs and Servicing Workshop | Carro Workshop',
    description:
      'Looking for a car servicing and car repair workshop in Singapore? Book now for the best and most reliable service for all your maintenance needs today!',
    image: 'https://carro.sg/assets/img/meta_carro_workshop.jpg',
    ...IMAGE_SIZE,
  },
  howItWorks: {
    title: 'How it Works | Car Repair and Maintenance Service | Carro Singapore',
    metaTitle: 'Transparent & Trustworthy Car Repairs and Servicing Workshop | Carro Workshop',
    description:
      'Wondering how does Carro’s car repair workshop works? Click here to learn more on how we provide reliable car mechanic services in Singapore now.',
    image: 'https://carro.sg/assets/img/meta_carro_workshop.jpg',
    ...IMAGE_SIZE,
  },
  getAQuote: {
    title: 'Get a Quote at the Best Car Workshop in Singapore | Carro Singapore',
    metaTitle: 'Transparent & Trustworthy Car Repairs and Servicing Workshop | Carro Workshop',
    description:
      'Wondering how does Carro’s car repair workshop works? Click here to learn more on how we provide reliable car mechanic services in Singapore now.',
    image: 'https://carro.sg/assets/img/meta_carro_workshop.jpg',
    ...IMAGE_SIZE,
  },
  newCar: {
    title: 'New Cars At Best Prices | Carro New Car Marketplace',
    metaTitle: 'Buy New Car At Best Prices | Carro New Car Marketplace',
    description: 'Get your next new car for the best prices in the market, exclusively at Carro.',
    image: 'https://carro.sg/assets/img/meta_newcar_home_100619.jpg',
    ...IMAGE_SIZE,
  },
  leasing: {
    title: 'Monthly Car Rental & Leasing Subscription Services | Carro',
    metaTitle: 'Carro.sg: Affordable Car Rental, Car Leasing and Car Subscription services',
    description:
      'Looking for a short-term car rental or car leasing company in Singapore? Subscribe to our monthly car rental service now & lease a car for a month, or for as long as you want!',
    image: 'https://carro.sg/assets/img/meta_leasing_100619.jpg',
    ...IMAGE_SIZE,
  },
  leap: {
    title: 'Rent Car Singapore | Lease Car Singapore | Leap by Carro',
    metaTitle:
      'Carro offers short-term car rental service in Singapore for new & pre-owned cars on a monthly basis. Rent a car by subscribing to our flexible car lease plans!',
    description:
      'Carro offers short-term car rental service in Singapore for new & pre-owned cars on a monthly basis. Rent a car by subscribing to our flexible car lease plans!',
    image: 'https://carro.sg/assets/img/meta_leasing_100619.jpg',
    ...IMAGE_SIZE,
  },
  anywhere: {
    title: "Singapore's 1st 24/7 Car Showroom I Carro Anywhere",
    metaTitle: 'Buy, Sell and Lease New and Used cars, Entirely Online | Carro Singapore',
    description:
      "View and inspect cars with greater convenience and hygiene in Singapore's first 24/7 contactless car showroom - Carro Anywhere. Experience the future of car buying today!",
    image: 'https://carro.sg/assets/img/meta_home_100619.jpg',
    ...IMAGE_SIZE,
  },
  certified: {
    title: 'Carro Certified',
    metaTitle: 'Carro Certified',
    description:
      "Certified pre-owned cars for sale at Carro's contactless second-hand car purchase service in Singapore. Buy a car with a 30-day guarantee & 3-day return policy.",
    image: 'https://carro.sg/assets/img/meta_home_100619.jpg',
    ...IMAGE_SIZE,
  },
  userProfileIndex: {
    en: {
      title: 'My Profile | Carro Singapore',
      metaTitle: 'My Profile | Carro Singapore',
      description: 'View personal information, browsing history and more. ',
      image: getStaticCDN('/assets/shared/meta-thumb/Carro_Metatag_1200x628.png'),
      ...IMAGE_SIZE,
    },
    'zh-SG': {
      title: '我的简介 | Carro 新加坡',
      metaTitle: '我的简介 | Carro 新加坡',
      description: '查看个人信息、浏览历史等。',
      image: getStaticCDN('/assets/shared/meta-thumb/Carro_Metatag_1200x628.png'),
      ...IMAGE_SIZE,
    },
    'ms-SG': {
      title: 'Profil saya | Carro Singapura',
      metaTitle: 'Profil saya | Carro Singapura',
      description: 'Lihat maklumat peribadi, sejarah penyemakan imbas dan banyak lagi.',
      image: getStaticCDN('/assets/shared/meta-thumb/Carro_Metatag_1200x628.png'),
      ...IMAGE_SIZE,
    },
  },
  userProfilePersonalInfo: {
    en: {
      title: 'Edit Personal Info | Carro Singapore',
      metaTitle: 'Edit Personal Info | Carro Singapore',
      description: 'Update personal information and contact details. ',
      image: getStaticCDN('/assets/shared/meta-thumb/Carro_Metatag_1200x628.png'),
      ...IMAGE_SIZE,
    },
    'zh-SG': {
      title: '编辑个人信息 | Carro 新加坡',
      metaTitle: '编辑个人信息 | Carro 新加坡',
      description: '更新个人信息和联系方式。',
      image: getStaticCDN('/assets/shared/meta-thumb/Carro_Metatag_1200x628.png'),
      ...IMAGE_SIZE,
    },
    'ms-SG': {
      title: 'Edit Maklumat Peribadi | Carro Singapura',
      metaTitle: 'Edit Maklumat Peribadi | Carro Singapura',
      description: 'Kemas kini maklumat peribadi dan butiran hubungan.',
      image: getStaticCDN('/assets/shared/meta-thumb/Carro_Metatag_1200x628.png'),
      ...IMAGE_SIZE,
    },
  },
  ccpoIndex: {
    en: {
      title: 'Carro Certified',
      metaTitle: 'Carro Certified',
      description:
        "Certified pre-owned cars for sale at Carro's contactless second-hand car purchase service in Singapore. Buy a car with a 30-day guarantee & 3-day return policy.",
      image: getStaticCDN('/assets/shared/meta-thumb/Carro_Metatag_1200x628.png'),
      ...IMAGE_SIZE,
    },
    // Todo_CCPO: waiting translation
    'zh-SG': {
      title: 'Carro Certified',
      metaTitle: 'Carro Certified',
      description:
        "Certified pre-owned cars for sale at Carro's contactless second-hand car purchase service in Singapore. Buy a car with a 30-day guarantee & 3-day return policy.",
      image: getStaticCDN('/assets/shared/meta-thumb/Carro_Metatag_1200x628.png'),
      ...IMAGE_SIZE,
    },
    'ms-SG': {
      title: 'Carro Disahkan',
      metaTitle: 'Carro Disahkan',
      description:
        'Kereta pra-milik yang disahkan untuk dijual di perkhidmatan pembelian kereta terpakai Carro di Singapura. Beli kereta dengan dasar pulangan jaminan 30 hari & 3 hari.',
      image: getStaticCDN('/assets/shared/meta-thumb/Carro_Metatag_1200x628.png'),
      ...IMAGE_SIZE,
    },
  },
  locateUsIndex: {
    en: {
      title: 'The Best Place to Buy & Sell Cars | Carro',
      metaTitle: 'The Best Place to Buy & Sell Cars | Carro',
      description:
        'Buy used cars, sell used cars online easily in Singapore at Carro. Buy & sell used cars easily & safely.',
      image: getStaticCDN('/assets/shared/meta-thumb/Carro_Metatag_1200x628.png'),
      ...IMAGE_SIZE,
    },
    'zh-SG': {
      title: 'The Best Place to Buy & Sell Cars | Carro',
      metaTitle: 'The Best Place to Buy & Sell Cars | Carro',
      description:
        'Buy used cars, sell used cars online easily in Singapore at Carro. Buy & sell used cars easily & safely.',
      image: getStaticCDN('/assets/shared/meta-thumb/Carro_Metatag_1200x628.png'),
      ...IMAGE_SIZE,
    },
    'ms-SG': {
      title: 'Tempat terbaik untuk membeli & menjual kereta | Carro',
      metaTitle: 'Tempat terbaik untuk membeli & menjual kereta | Carro',
      description:
        'Beli kereta terpakai, menjual kereta terpakai dalam talian dengan mudah di Carro Singapura. Beli & menjual kereta terpakai dengan mudah & selamat.',
      image: getStaticCDN('/assets/shared/meta-thumb/Carro_Metatag_1200x628.png'),
      ...IMAGE_SIZE,
    },
  },
  expiredOTT: {
    en: {
      title: 'Check In Failed',
      description:
        'Oops! Your Check-in Has Failed because your link has expired. Please find a Product Genius near you to check-in again!',
    },
    'zh-SG': {
      title: 'Check In Failed',
      description:
        'Oops! Your Check-in Has Failed because your link has expired. Please find a Product Genius near you to check-in again!',
    },
  },
  viewingHistoryIndex: {
    en: {
      title: 'Viewing History | Carro Singapore',
      metaTitle: 'Viewing History | Carro Singapore',
      description: 'View personal information, browsing history and more.',
      image: getStaticCDN('/assets/shared/meta-thumb/Carro_Metatag_1200x628.png'),
      ...IMAGE_SIZE,
    },
    'zh-SG': {
      title: '浏览纪录 | Carro 新加坡',
      metaTitle: '浏览纪录 | Carro 新加坡',
      description: '查看个人信息、浏览历史等。',
      image: getStaticCDN('/assets/shared/meta-thumb/Carro_Metatag_1200x628.png'),
      ...IMAGE_SIZE,
    },
    'ms-SG': {
      title: 'Melihat Sejarah | Carro Singapura',
      metaTitle: 'Melihat Sejarah | Carro Singapura',
      description: 'Lihat maklumat peribadi, menyemak imbas sejarah dan banyak lagi.',
      image: getStaticCDN('/assets/shared/meta-thumb/Carro_Metatag_1200x628.png'),
      ...IMAGE_SIZE,
    },
  },
  error: {
    en: {
      title: 'Error | Carro Singapore',
      metaTitle: 'Error | Carro Singapore',
    },
    'zh-SG': {
      title: '错误 | Carro 新加坡',
      metaTitle: '错误 | Carro 新加坡',
    },
    'ms-SG': {
      title: 'Ralat | Carro Singapura',
      metaTitle: 'Ralat | Carro Singapura',
    },
  },
  buyNew: {
    title: 'Carro New Cars: Now with $0 Down Payment',
    metaTitle: 'Carro New Cars: Now with $0 Down Payment',
    description:
      'Explore our brand new, parallel imported cars with competitive prices, 100% financing and comprehensive warranty coverage. Find out more.',
    image: getStaticCDN('/assets/shared/meta-thumb/Carro_Metatag_1200x628.png'),
    ...IMAGE_SIZE,
  },
};

const TH_META_DATA: MetaDataProps | MetaDataPropsWithLang = {
  index: {
    title: `คิดจะขายรถ คิดถึงเรา | Carro`,
    metaTitle: 'คิดจะขายรถ คิดถึงเรา | Carro',
    description:
      'คิดจะขายรถ คิดถึง Carro | Carro ย่อมาจาก Car Hero เปรียบเสมือนฮีโร่ในทุกๆด้านของรถของทุกคน โดยเพียงส่งข้อมูลรถทีต้องการจะขาย Car Hero จะทำการค้นหาราคาที่ดีสุดผ่านระบบเฉพาะของ Carro และส่งราคาที่เหมาะสมที่สุดสำหรับรถคันนั้นๆ โดยสามารถปรึกษาเรื่องการขายรถกับพวกเราได้ตลอดเวลา',
    image: getStaticCDN('/assets/shared/meta-thumb/Carro_Metatag_1200x628.png'),
    ...IMAGE_SIZE,
  },
  home: {
    title: 'Carro เว็บซื้อ-ขายรถมือสอง สะดวก ปลอดภัย เชื่อถือได้ บริการฟรี!',
    metaTitle: 'Carro เว็บซื้อ-ขายรถมือสอง สะดวก ปลอดภัย เชื่อถือได้ บริการฟรี!',
    description:
      'ต้องการซื้อ-ขายรถยนต์ นึกถึงคาร์โร จะซื้อ-ขายรถบ้านบนเว็บก็ง่าย หรือจะซื้อ-ขายรถจำนวนมากก็ทำได้ผ่านแอปฯ Carro Wholesale สะดวก ปลอดภัย ถูกใจทั้งผู้ซื้อและผู้ขาย',
    image: getStaticCDN('/assets/shared/meta-thumb/Carro_Metatag_1200x628.png'),
    ...IMAGE_SIZE,
  },
  sell: {
    title: 'บริการขายรถจบด่วน ภายใน 24ชม. ขายฟรี ได้เงินชัวร์ | Carro Express',
    metaTitle: 'บริการขายรถจบด่วน ภายใน 24ชม. ขายฟรี ได้เงินชัวร์ | Carro Express',
    description:
      'ร้อนเงิน ร้อนใจ ไม่รู้จะขายรถที่ไหน? มาที่ Carro สิ พร้อมรับซื้อรถของคุณในราคาที่คุณพอใจ ขายรถได้เร็ว บริการฟรี ไม่มีค่าธรรมเนียม รับเงินสดทันที',
    image: getStaticCDN('/assets/shared/meta-thumb/Carro_Metatag_1200x628.png'),
    ...IMAGE_SIZE,
  },
  certified: {
    title: 'รับประกันคุณภาพโดย Carro',
    metaTitle: 'รับประกันคุณภาพโดย Carro',
    description:
      'Carro Certified ให้คุณมั่นใจในคุณภาพตามมาตรฐานการตรวจสอบจากผู้เชี่ยวชาญ ทั้งภายนอกและภายในมากถึง 160 จุด รับประกันเครื่องยนต์และเกียร์ ไม่กรอไมล์ ไม่ประสบอุบัติเหตุหนัก ไฟไหม้ หรือน้ำท่วม',
    image: getStaticCDN('/static/img/logo-orange.jpg'),
    ...IMAGE_SIZE,
  },
  userProfileIndex: {
    en: {
      title: 'My Profile | Carro Thailand',
      metaTitle: 'My Profile | Carro Thailand',
      description: 'View personal information, browsing history and more. ',
      image: getStaticCDN('/assets/shared/meta-thumb/Carro_Metatag_1200x628.png'),
      ...IMAGE_SIZE,
    },
    th: {
      title: 'โปรไฟล์ของฉัน | Carro Thailand',
      metaTitle: 'โปรไฟล์ของฉัน | Carro Thailand',
      description: 'ดูข้อมูลส่วนบุคคล ประวัติการเข้าชม และอื่นๆ',
      image: getStaticCDN('/assets/shared/meta-thumb/Carro_Metatag_1200x628.png'),
      ...IMAGE_SIZE,
    },
  },
  userProfilePersonalInfo: {
    en: {
      title: 'Edit Personal Info | Carro Thailand',
      metaTitle: 'Edit Personal Info | Carro Thailand',
      description: 'Update personal information and contact details. ',
      image: getStaticCDN('/assets/shared/meta-thumb/Carro_Metatag_1200x628.png'),
      ...IMAGE_SIZE,
    },
    th: {
      title: 'แก้ไขข้อมูลส่วนบุคคล | Carro Thailand',
      metaTitle: 'แก้ไขข้อมูลส่วนบุคคล | Carro Thailand',
      description: 'อัปเดตข้อมูลส่วนบุคคลและรายละเอียดการติดต่อ',
      image: getStaticCDN('/assets/shared/meta-thumb/Carro_Metatag_1200x628.png'),
      ...IMAGE_SIZE,
    },
  },
  ccpoIndex: {
    en: {
      title: 'Carro Certified',
      metaTitle: 'Carro Certified',
      description:
        'Carro Certified cars go through a rigorous 160-point inspection checklist. Enjoy 12-month warranty for engine and gearbox. No mileage tampering, no major accidents, fire or flood damage.',
      image: getStaticCDN('/assets/shared/meta-thumb/Carro_Metatag_1200x628.png'),
      ...IMAGE_SIZE,
    },
    th: {
      title: 'รับประกันคุณภาพโดย Carro',
      metaTitle: 'รับประกันคุณภาพโดย Carro',
      description:
        'Carro Certified ให้คุณมั่นใจในคุณภาพตามมาตรฐานการตรวจสอบจากผู้เชี่ยวชาญ ทั้งภายนอกและภายในมากถึง 160 จุด รับประกันเครื่องยนต์และเกียร์ ไม่กรอไมล์ ไม่ประสบอุบัติเหตุหนัก ไฟไหม้ หรือน้ำท่วม',
      image: getStaticCDN('/assets/shared/meta-thumb/Carro_Metatag_1200x628.png'),
      ...IMAGE_SIZE,
    },
  },
  locateUsIndex: {
    en: {
      title: 'The Best Place to Buy & Sell Cars | Carro',
      metaTitle: 'The Best Place to Buy & Sell Cars | Carro',
      description:
        'Buy used cars, sell used cars online easily in Thailand at Carro. Buy & sell used cars easily & safely.',
      image: getStaticCDN('/assets/shared/meta-thumb/Carro_Metatag_1200x628.png'),
      ...IMAGE_SIZE,
    },
    th: {
      title: 'The Best Place to Buy & Sell Cars | Carro',
      metaTitle: 'The Best Place to Buy & Sell Cars | Carro',
      description:
        'Buy used cars, sell used cars online easily in Thailand at Carro. Buy & sell used cars easily & safely.',
      image: getStaticCDN('/assets/shared/meta-thumb/Carro_Metatag_1200x628.png'),
      ...IMAGE_SIZE,
    },
  },
  viewingHistoryIndex: {
    en: {
      title: 'Viewing History | Carro Thailand',
      metaTitle: 'Viewing History | Carro Thailand',
      description: 'View personal information, browsing history and more.',
      image: getStaticCDN('/assets/shared/meta-thumb/Carro_Metatag_1200x628.png'),
      ...IMAGE_SIZE,
    },
    th: {
      title: 'ประวัติการเข้าชม | Carro Thailand',
      metaTitle: 'ประวัติการเข้าชม | Carro Thailand',
      description: 'ดูข้อมูลส่วนบุคคล ประวัติการเข้าชม และอื่นๆ',
      image: getStaticCDN('/assets/shared/meta-thumb/Carro_Metatag_1200x628.png'),
      ...IMAGE_SIZE,
    },
  },
  error: {
    en: {
      title: 'Error | Carro Thailand',
      metaTitle: 'Error | Carro Thailand',
    },
    th: {
      title: 'ความผิดพลาด | Carro Thailand',
      metaTitle: 'ความผิดพลาด | Carro Thailand',
    },
  },
};

const ID_META_DATA: MetaDataProps | MetaDataPropsWithLang = {
  index: {
    title: `Platform mobil Bekas Terbesar Di Indonesia | Carro Indonesia`,
    metaTitle: 'Platform Mobil Bekas Terbesar di Indonesia',
    description:
      'Pilih mobil bekas dari berbagai pilihan ✓ Mobil bekas berkualitas, telah diinspeksi dan bergaransi ✓ Pelayanan dan harga terbaik ✓ Baca review dan harga mobil terbaru 2023 ✓',
    image: getStaticCDN('/assets/shared/meta-thumb/Carro_Metatag_1200x628.png'),
    ...IMAGE_SIZE,
  },
  home: {
    title: `Platform mobil Bekas Terbesar Di Indonesia | Carro Indonesia`,
    metaTitle: 'Platform Mobil Bekas Terbesar di Indonesia',
    description:
      'Pilih mobil bekas dari berbagai pilihan ✓ Mobil bekas berkualitas, telah diinspeksi dan bergaransi ✓ Pelayanan dan harga terbaik ✓ Baca review dan harga mobil terbaru 2023 ✓',
    image: getStaticCDN('/assets/shared/meta-thumb/Carro_Metatag_1200x628.png'),
    ...IMAGE_SIZE,
  },
  sell: {
    title: 'Jual Mobil Cepat & Mudah | Carro Indonesia',
    metaTitle: 'Jual Mobil Bekas di Indonesia Cepat & Mudah Gratis',
    description:
      'Jual mobil bekas cepat dan mudah ✓ Pelayanan jual dan inspeksi mobil seken gratis dan terpercaya ✓ Dapatkan harga mobil bekas tertinggi ✓',
    image: getStaticCDN('/assets/shared/meta-thumb/Carro_Metatag_1200x628.png'),
    ...IMAGE_SIZE,
  },
  automall: {
    title: 'Showroom Mobil Bekas Bersertifikasi Terbesar | Carro Automall',
    metaTitle: 'Showroom Mobil Bekas Bersertifikasi Terbesar | Carro Automall',
    description:
      'Carro Automall menyediakan ratusan stok mobil bekas bersertifikasi dengan keuntungan 90 hari garansi transmisi mesin, bebas banjir tabrak dan bukan mobil curian, serta Beli sekarang!',
    image: getStaticCDN('/static/img/meta_sell_100619.jpg'),
    ...IMAGE_SIZE,
  },
  contact: {
    title: 'Hubungi Kami | Carro Indonesia',
    metaTitle: 'Hubungi Kami | Carro Indonesia',
    description:
      '[Carro.id](http://carro.id/) adalah platform transaksi mobil bekas bersertifikasi di Indonesia. [Carro.id](http://carro.id/) inspeksi real-time, garansi mobil, dan 50 opsi pembiayaan cash & kredit.',
    image: getStaticCDN('/static/img/meta_sell_100619.jpg'),
    ...IMAGE_SIZE,
  },
  certified: {
    title: 'Carro Certified',
    metaTitle: 'Carro Certified',
    description: 'Carro Certified',
    image: getStaticCDN('/static/img/meta_sell_100619.jpg'),
    ...IMAGE_SIZE,
  },
  certifiedInspectionPoints: {
    title: 'Carro Certified',
    metaTitle: 'Carro Certified',
    description: 'Carro Certified',
    image: getStaticCDN('/static/img/meta_sell_100619.jpg'),
    ...IMAGE_SIZE,
  },
  userProfileIndex: {
    en: {
      title: 'My Profile | Carro Indonesia',
      metaTitle: 'My Profile | Carro Indonesia',
      description: 'View personal information, browsing history and more. ',
      image: getStaticCDN('/assets/shared/meta-thumb/Carro_Metatag_1200x628.png'),
      ...IMAGE_SIZE,
    },
    id: {
      title: 'Profil Saya | Carro Indonesia',
      metaTitle: 'Profil Saya | Carro Indonesia',
      description: 'Lihat informasi pribadi, riwayat penelusuran, dan lainnya.',
      image: getStaticCDN('/assets/shared/meta-thumb/Carro_Metatag_1200x628.png'),
      ...IMAGE_SIZE,
    },
  },
  userProfilePersonalInfo: {
    en: {
      title: 'Edit Personal Info | Carro Indonesia',
      metaTitle: 'Edit Personal Info | Carro Indonesia',
      description: 'Update personal information and contact details. ',
      image: getStaticCDN('/assets/shared/meta-thumb/Carro_Metatag_1200x628.png'),
      ...IMAGE_SIZE,
    },
    id: {
      title: 'Sunting Info Pribadi | Carro Indonesia',
      metaTitle: 'Sunting Info Pribadi | Carro Indonesia',
      description: 'Perbarui informasi pribadi dan detail kontak.',
      image: getStaticCDN('/assets/shared/meta-thumb/Carro_Metatag_1200x628.png'),
      ...IMAGE_SIZE,
    },
  },
  ccpoIndex: {
    en: {
      title: 'Carro Certified',
      metaTitle: 'Carro Certified',
      description:
        'Carro Certified cars go through a rigorous 160-point inspection checklist. Enjoy 12-month warranty for engine and gearbox. No mileage tampering, no major accidents, fire or flood damage.',
      image: getStaticCDN('/assets/shared/meta-thumb/Carro_Metatag_1200x628.png'),
      ...IMAGE_SIZE,
    },
    id: {
      title: 'Carro Certified',
      metaTitle: 'Carro Certified',
      description:
        'Carro Certified cars go through a rigorous 160-point inspection checklist. Enjoy 12-month warranty for engine and gearbox. No mileage tampering, no major accidents, fire or flood damage.',
      image: getStaticCDN('/assets/shared/meta-thumb/Carro_Metatag_1200x628.png'),
      ...IMAGE_SIZE,
    },
  },
  locateUsIndex: {
    en: {
      title: 'The Best Place to Buy & Sell Cars | Carro',
      metaTitle: 'The Best Place to Buy & Sell Cars | Carro',
      description:
        'Buy used cars, sell used cars online easily in Indonesia at Carro. Buy & sell used cars easily & safely.',
      image: getStaticCDN('/assets/shared/meta-thumb/Carro_Metatag_1200x628.png'),
      ...IMAGE_SIZE,
    },
    id: {
      title: 'The Best Place to Buy & Sell Cars | Carro',
      metaTitle: 'The Best Place to Buy & Sell Cars | Carro',
      description:
        'Buy used cars, sell used cars online easily in Indonesia at Carro. Buy & sell used cars easily & safely.',
      image: getStaticCDN('/assets/shared/meta-thumb/Carro_Metatag_1200x628.png'),
      ...IMAGE_SIZE,
    },
  },
  viewingHistoryIndex: {
    en: {
      title: 'Viewing History | Carro Indonesia',
      metaTitle: 'Viewing History | Carro Indonesia',
      description: 'View personal information, browsing history and more. ',
      image: getStaticCDN('/assets/shared/meta-thumb/Carro_Metatag_1200x628.png'),
      ...IMAGE_SIZE,
    },
    id: {
      title: 'Riwayat Penelusuran | Carro Indonesia',
      metaTitle: 'Riwayat Penelusuran | Carro Indonesia',
      description: 'Lihat informasi pribadi, riwayat penelusuran, dan lainnya.',
      image: getStaticCDN('/assets/shared/meta-thumb/Carro_Metatag_1200x628.png'),
      ...IMAGE_SIZE,
    },
  },
  error: {
    en: {
      title: 'Error | Carro Indonesia',
      metaTitle: 'Error | Carro Indonesia',
    },
    id: {
      title: 'Salah | Carro Indonesia',
      metaTitle: 'Salah | Carro Indonesia',
    },
  },
};

const JP_META_DATA: MetaDataProps | MetaDataPropsWithLang = {
  home: {
    en: {
      title: 'The Best Place to Buy and Sell Used Cars | Carro Japan',
      metaTitle: 'The Best Place to Buy and Sell Used Cars | Carro Japan',
      description:
        'Carro is the largest auto trading market in Southeast Asia that provides car owners with an open and transparent full-service experience, and challenges the traditional auto industry model with technology. We are worthy of your trust.',
      image: getStaticCDN('/assets/shared/meta-thumb/Carro_Metatag_1200x628.png'),
      ...IMAGE_SIZE,
    },
    ja: {
      title: '安心安全な中古車売買を | Carro Japan',
      metaTitle: '安心安全な中古車売買を | Carro Japan',
      description:
        'アジア太平洋地域No. 1のオンライン中古車マーケットプレイス・Carroは、オープンで透明性の高い多様なサービスの提供と先進的なテクノロジーの活用により自動車業界に革新を起こします。',
      image: getStaticCDN('/assets/shared/meta-thumb/Carro_Metatag_1200x628.png'),
      ...IMAGE_SIZE,
    },
  },
  sell: {
    en: {
      title: 'Sell Your Used Car in Japan Hassle-Free',
      metaTitle: 'Sell Your Used Car in Japan Hassle-Free',
      description: `Sell your car quickly, easily, & hassle free with Carro Japan. Just come for our free valuation and we'll take care of everything.`,
      image: getStaticCDN('/assets/shared/meta-thumb/Carro_Metatag_1200x628.png'),
      ...IMAGE_SIZE,
    },
    ja: {
      title: '簡単なステップで車を売る',
      metaTitle: '簡単なステップで車を売る',
      description: `簡単・お手軽、ストレスフリーにお車を売却。無料査定を受けるだけで、あとは安心してすべてCarroにお任せください。`,
      image: getStaticCDN('/assets/shared/meta-thumb/Carro_Metatag_1200x628.png'),
      ...IMAGE_SIZE,
    },
  },
  userProfileIndex: {
    en: {
      title: 'My Profile | Carro Japan',
      metaTitle: 'My Profile | Carro Japan',
      description: 'View personal information, browsing history and more. ',
      image: getStaticCDN('/assets/shared/meta-thumb/Carro_Metatag_1200x628.png'),
      ...IMAGE_SIZE,
    },
    ja: {
      title: 'マイプロフィール | Carro 日本',
      metaTitle: 'マイプロフィール | Carro 日本',
      description: '登録情報、閲覧履歴などを表示。',
      image: getStaticCDN('/assets/shared/meta-thumb/Carro_Metatag_1200x628.png'),
      ...IMAGE_SIZE,
    },
  },
  userProfilePersonalInfo: {
    en: {
      title: 'Edit Personal Info | Carro Japan',
      metaTitle: 'Edit Personal Info | Carro Japan',
      description: 'Update personal information and contact details. ',
      image: getStaticCDN('/assets/shared/meta-thumb/Carro_Metatag_1200x628.png'),
      ...IMAGE_SIZE,
    },
    ja: {
      title: '登録情報の編集 | Carro 日本',
      metaTitle: '登録情報の編集 | Carro 日本',
      description: '登録情報、連絡先の詳細を更新。',
      image: getStaticCDN('/assets/shared/meta-thumb/Carro_Metatag_1200x628.png'),
      ...IMAGE_SIZE,
    },
  },
  ccpoIndex: {
    en: {
      title: 'Carro Certified',
      metaTitle: 'Carro Certified',
      description:
        'Carro Certified cars go through a rigorous 160-point inspection checklist. Enjoy 12-month warranty for engine and gearbox. No mileage tampering, no major accidents, fire or flood damage.',
      image: getStaticCDN('/assets/shared/meta-thumb/Carro_Metatag_1200x628.png'),
      ...IMAGE_SIZE,
    },
    ja: {
      title: 'Carro 認定車',
      metaTitle: 'Carro 認定車',
      description:
        'Carro 認定車は、160 項目に及ぶ厳格な検査チェックリストを通過します。エンジンとギアボックスの 12 か月保証をお楽しみください。走行距離の改ざん、重大な事故、火災や水害はありません。',
      image: getStaticCDN('/assets/shared/meta-thumb/Carro_Metatag_1200x628.png'),
      ...IMAGE_SIZE,
    },
  },
  locateUsIndex: {
    en: {
      title: 'The Best Place to Buy & Sell Cars | Carro',
      metaTitle: 'The Best Place to Buy & Sell Cars | Carro',
      description:
        'Buy used cars, sell used cars online easily in Japan at Carro. Buy & sell used cars easily & safely.',
      image: getStaticCDN('/assets/shared/meta-thumb/Carro_Metatag_1200x628.png'),
      ...IMAGE_SIZE,
    },
    ja: {
      title: '安心安全な中古車売買を| Carro Japan',
      metaTitle: '安心安全な中古車売買を| Carro Japan',
      description: 'Carroでお気軽、便利で安心なオンライン中古車売買を。',
      image: getStaticCDN('/assets/shared/meta-thumb/Carro_Metatag_1200x628.png'),
      ...IMAGE_SIZE,
    },
  },
  contact: {
    en: {
      title: 'Contact Us | Carro Japan',
      metaTitle: 'Contact Us | Carro Japan',
      description:
        'Do you have any enquiries? Pay us a visit or contact us via our hotline or email! We are open everyday except public holidays. Click here now.',
      image: getStaticCDN('/assets/shared/meta-thumb/Carro_Metatag_1200x628.png'),
      ...IMAGE_SIZE,
    },
    ja: {
      title: 'お問い合わせ | Carro 日本',
      metaTitle: 'お問い合わせ | Carro 日本',
      description:
        'ご質問がある場合は、こちらからどうぞ。ご来店やお電話、メールでも対応いたします。基本的に毎日営業していますので、お気軽にご連絡ください。',
      image: getStaticCDN('/assets/shared/meta-thumb/Carro_Metatag_1200x628.png'),
      ...IMAGE_SIZE,
    },
  },
  viewingHistoryIndex: {
    en: {
      title: 'Viewing History | Carro Japan',
      metaTitle: 'Viewing History | Carro Japan',
      description: 'View personal information, browsing history and more. ',
      image: getStaticCDN('/assets/shared/meta-thumb/Carro_Metatag_1200x628.png'),
      ...IMAGE_SIZE,
    },
    ja: {
      title: '閲覧履歴 | Carro 日本',
      metaTitle: '閲覧履歴 | Carro 日本',
      description: '登録情報、閲覧履歴などを表示。',
      image: getStaticCDN('/assets/shared/meta-thumb/Carro_Metatag_1200x628.png'),
      ...IMAGE_SIZE,
    },
  },
  error: {
    en: {
      title: 'Error | Carro Japan',
      metaTitle: 'Error | Carro Japan',
    },
    ja: {
      title: 'エラー | Carro 日本',
      metaTitle: 'エラー | Carro 日本',
    },
  },
  about: {
    en: {
      title: 'About Us | Carro Japan',
      metaTitle: 'About Us | Carro Japan',
      description:
        "Asia Pacific's largest online used car market. The best place to buy and sell second-hand & used cars in Japan.",
      image: getStaticCDN('/assets/shared/meta-thumb/Carro_Metatag_1200x628.png'),
    },
    ja: {
      title: 'About Us | Carro Japan',
      metaTitle: 'About Us | Carro Japan',
      description: '安心安全な中古車売買をご提供するアジア太平洋最大のオンライン中古車マーケットプレイス。',
      image: getStaticCDN('/assets/shared/meta-thumb/Carro_Metatag_1200x628.png'),
    },
  },
};

const HK_META_DATA: MetaDataProps | MetaDataPropsWithLang = {
  index: {
    en: {
      title: 'Buy and Sell Second-Hand Cars at The Best Place in Hong Kong',
      metaTitle: 'Buy and Sell Second-Hand Cars at The Best Place in Hong Kong',
      description:
        'Our AI-driven used car marketplace provides you with transparency and quality assurance when buying and selling a second-hand car.',
      image: getStaticCDN('/assets/shared/meta-thumb/Carro_Metatag_1200x628.png'),
      ...IMAGE_SIZE,
    },
    'zh-Hant-HK': {
      title: '香港買賣二手車的最佳地點',
      metaTitle: '香港買賣二手車的最佳地點',
      description: '我們的人工智慧驅動二手車市場，為您買賣二手車提供透明度和品質保證。',
      image: getStaticCDN('/assets/shared/meta-thumb/Carro_Metatag_1200x628.png'),
      ...IMAGE_SIZE,
    },
    'zh-Hans-HK': {
      title: '香港买卖二手车的最佳地点',
      metaTitle: '香港买卖二手车的最佳地点',
      description: '我们的人工智能驱动二手车市场，为您买卖二手车提供透明度和质量保证。',
      image: getStaticCDN('/assets/shared/meta-thumb/Carro_Metatag_1200x628.png'),
      ...IMAGE_SIZE,
    },
  },
  home: {
    en: {
      title: 'Buy and Sell Second-Hand Cars at The Best Place in Hong Kong',
      metaTitle: 'Buy and Sell Second-Hand Cars at The Best Place in Hong Kong',
      description:
        'Our AI-driven used car marketplace provides you with transparency and quality assurance when buying and selling a second-hand car.',
      image: getStaticCDN('/assets/shared/meta-thumb/Carro_Metatag_1200x628.png'),
      ...IMAGE_SIZE,
    },
    'zh-Hant-HK': {
      title: '香港買賣二手車的最佳地點',
      metaTitle: '香港買賣二手車的最佳地點',
      description: '我們的人工智慧驅動二手車市場，為您買賣二手車提供透明度和品質保證。',
      image: getStaticCDN('/assets/shared/meta-thumb/Carro_Metatag_1200x628.png'),
      ...IMAGE_SIZE,
    },
    'zh-Hans-HK': {
      title: '香港买卖二手车的最佳地点',
      metaTitle: '香港买卖二手车的最佳地点',
      description: '我们的人工智能驱动二手车市场，为您买卖二手车提供透明度和质量保证。',
      image: getStaticCDN('/assets/shared/meta-thumb/Carro_Metatag_1200x628.png'),
      ...IMAGE_SIZE,
    },
  },
  sell: {
    en: {
      title: 'Sell Car For The Highest Price | Carro Hong Kong',
      metaTitle: 'Sell Car For The Highest Price | Carro Hong Kong',
      description: `Sell or consign your car in Hong Kong at the highest price on the Carro car selling platform. Fair & Expert Valuation. Get a response in just 15 mins.`,
      image: getStaticCDN('/assets/shared/meta-thumb/Carro_Metatag_1200x628.png'),
      ...IMAGE_SIZE,
    },
    'zh-Hant-HK': {
      title: '以更高的價格出售汽車 | Carro 香港',
      metaTitle: '以更高的價格出售汽車 | Carro 香港',
      description: `在Carro汽車銷售平台以最高價格在香港出售或寄賣您的汽車。公平和專家估價。沒有低價優惠。只需 15 分鐘即可獲得回覆。`,
      image: getStaticCDN('/assets/shared/meta-thumb/Carro_Metatag_1200x628.png'),
      ...IMAGE_SIZE,
    },
    'zh-Hans-HK': {
      title: '以更高的价格出售汽车 | Carro 香港',
      metaTitle: '以更高的价格出售汽车 | Carro 香港',
      description: `在Carro汽车销售平台以最高价格在香港出售或寄卖您的汽车。公平和专家估价。没有低价优惠。只需 15 分钟即可获得回复。`,
      image: getStaticCDN('/assets/shared/meta-thumb/Carro_Metatag_1200x628.png'),
      ...IMAGE_SIZE,
    },
  },
  userProfileIndex: {
    en: {
      title: 'My Profile | Carro Hong Kong',
      metaTitle: 'My Profile | Carro Hong Kong',
      description: 'View personal information, browsing history and more. ',
      image: getStaticCDN('/assets/shared/meta-thumb/Carro_Metatag_1200x628.png'),
      ...IMAGE_SIZE,
    },
    'zh-Hant-HK': {
      title: '我的簡介 | Carro 香港',
      metaTitle: '我的簡介 | Carro 香港',
      description: '查看個人資料、瀏覽紀錄等。',
      image: getStaticCDN('/assets/shared/meta-thumb/Carro_Metatag_1200x628.png'),
      ...IMAGE_SIZE,
    },
    'zh-Hans-HK': {
      title: '我的简介 | Carro 香港',
      metaTitle: '我的简介 | Carro 香港',
      description: '查看个人信息、浏览历史等。',
      image: getStaticCDN('/assets/shared/meta-thumb/Carro_Metatag_1200x628.png'),
      ...IMAGE_SIZE,
    },
  },
  userProfilePersonalInfo: {
    en: {
      title: 'Edit Personal Info | Carro Hong Kong',
      metaTitle: 'Edit Personal Info | Carro Hong Kong',
      description: 'Update personal information and contact details. ',
      image: getStaticCDN('/assets/shared/meta-thumb/Carro_Metatag_1200x628.png'),
      ...IMAGE_SIZE,
    },
    'zh-Hant-HK': {
      title: '編輯個人資料 | Carro 香港',
      metaTitle: '編輯個人資料 | Carro 香港',
      description: '更新個人資訊和聯絡方式。',
      image: getStaticCDN('/assets/shared/meta-thumb/Carro_Metatag_1200x628.png'),
      ...IMAGE_SIZE,
    },
    'zh-Hans-HK': {
      title: '编辑个人信息 |  Carro 香港',
      metaTitle: '编辑个人信息 |  Carro 香港',
      description: '更新个人信息和联系方式。',
      image: getStaticCDN('/assets/shared/meta-thumb/Carro_Metatag_1200x628.png'),
      ...IMAGE_SIZE,
    },
  },
  ccpoIndex: {
    en: {
      title: 'Carro Certified',
      metaTitle: 'Carro Certified | Carro Hong Kong',
      description:
        'Our in-house certification process and guarantee isn’t just a stamp of quality and approval. It’s a reflection of our promise to give you the peace of mind you deserve. ',
      image: getStaticCDN('/assets/shared/meta-thumb/Carro_Metatag_1200x628.png'),
      ...IMAGE_SIZE,
    },
    'zh-Hans-HK': {
      title: 'Carro 官方认证',
      metaTitle: 'Carro 官方认证  | Carro 香港',
      description: '我们的内部认证流程和保证不单是质量和批准的印记, 它还体现了我们对您所许下的安心承诺。',
      image: getStaticCDN('/assets/shared/meta-thumb/Carro_Metatag_1200x628.png'),
      ...IMAGE_SIZE,
    },
    'zh-Hant-HK': {
      title: 'Carro 官方認證',
      metaTitle: 'Carro 官方認證 | Carro 香港',
      description: '我們的內部認證流程和保證不僅是質素和批准的標誌，更體現了我們致力為您提供安心服務的承諾。',
      image: getStaticCDN('/assets/shared/meta-thumb/Carro_Metatag_1200x628.png'),
      ...IMAGE_SIZE,
    },
  },
  locateUsIndex: {
    en: {
      title: 'The Best Place to Buy & Sell Cars | Carro',
      metaTitle: 'The Best Place to Buy & Sell Cars | Carro',
      description:
        'Buy used cars, sell used cars, consign cars online easily in Hong Kong. Buy, sell & consign used cars easily & safely. ',
      image: getStaticCDN('/assets/shared/meta-thumb/Carro_Metatag_1200x628.png'),
      ...IMAGE_SIZE,
    },
    'zh-Hant-HK': {
      title: '香港買賣二手車的最佳地點 | Carro 香港',
      metaTitle: '香港買賣二手車的最佳地點 | Carro 香港',
      description: '在香港輕鬆在線購買二手車、出售二手車、寄賣二手汽車。輕鬆、安全地購買、出售和寄賣二手車。',
      image: getStaticCDN('/assets/shared/meta-thumb/Carro_Metatag_1200x628.png'),
      ...IMAGE_SIZE,
    },
    'zh-Hans-HK': {
      title: '香港买卖二手车的最佳地点 | Carro 香港',
      metaTitle: '香港买卖二手车的最佳地点 | Carro 香港',
      description: '在香港轻松在线购买二手车、出售二手车、寄卖二手汽车。轻松、安全地购买、出售和寄卖二手车。',
      image: getStaticCDN('/assets/shared/meta-thumb/Carro_Metatag_1200x628.png'),
      ...IMAGE_SIZE,
    },
  },
  contact: {
    en: {
      title: 'Contact Us | Carro Hong Kong',
      metaTitle: 'Contact Us | Carro Hong Kong',
      description:
        'Do you have any enquiries? Visit us or contact us via our hotline or email! We are open everyday except public holidays. ',
      image: getStaticCDN('/assets/shared/meta-thumb/Carro_Metatag_1200x628.png'),
      ...IMAGE_SIZE,
    },
    'zh-Hant-HK': {
      title: '聯絡我們 | Carro 香港',
      metaTitle: '聯絡我們 | Carro 香港',
      description:
        '您有任何疑問嗎？拜訪我們或透過我們的熱線或電子郵件與我們聯繫！除公共假日外，我們每天都營業。馬上點擊這裡。',
      image: getStaticCDN('/assets/shared/meta-thumb/Carro_Metatag_1200x628.png'),
      ...IMAGE_SIZE,
    },
    'zh-Hans-HK': {
      title: '联系我们 | Carro 香港',
      metaTitle: '联系我们 | Carro 香港',
      description:
        '您有任何疑问吗？拜访我们或通过我们的热线或电子邮件与我们联系！除公共假期外，我们每天都营业。马上点击这里。',
      image: getStaticCDN('/assets/shared/meta-thumb/Carro_Metatag_1200x628.png'),
      ...IMAGE_SIZE,
    },
  },
  about: {
    en: {
      title: 'About Us | Carro Hong Kong',
      metaTitle: 'About Us | Carro Hong Kong',
      description: 'The Best Place to Buy and Sell Second-Hand & Used Cars in Hong Kong',
      image: getStaticCDN('/assets/shared/meta-thumb/Carro_Metatag_1200x628.png'),
    },
    'zh-Hant-HK': {
      title: '關於我們 | Carro 香港',
      metaTitle: '關於我們 | Carro 香港',
      description: '香港買賣二手車的最佳地點',
      image: getStaticCDN('/assets/shared/meta-thumb/Carro_Metatag_1200x628.png'),
      ...IMAGE_SIZE,
    },
    'zh-Hans-HK': {
      title: '关于我们 | Carro 香港',
      metaTitle: '关于我们 | Carro 香港',
      description: '香港买卖二手车的最佳地点',
      image: getStaticCDN('/assets/shared/meta-thumb/Carro_Metatag_1200x628.png'),
      ...IMAGE_SIZE,
    },
  },
  viewingHistoryIndex: {
    en: {
      title: 'Viewing History | Carro Hong Kong',
      metaTitle: 'Viewing History | Carro Hong Kong',
      description: 'View personal information, browsing history and more.',
      image: getStaticCDN('/assets/shared/meta-thumb/Carro_Metatag_1200x628.png'),
      ...IMAGE_SIZE,
    },
    'zh-Hant-HK': {
      title: '瀏覽歷史 | Carro 香港',
      metaTitle: '瀏覽歷史 | Carro 香港',
      description: '查看個人資料、瀏覽歷史等。',
      image: getStaticCDN('/assets/shared/meta-thumb/Carro_Metatag_1200x628.png'),
      ...IMAGE_SIZE,
    },
    'zh-Hans-HK': {
      title: '浏览历史 | Carro 香港',
      metaTitle: '浏览历史 | Carro 香港',
      description: '查看个人信息、浏览历史等。',
      image: getStaticCDN('/assets/shared/meta-thumb/Carro_Metatag_1200x628.png'),
      ...IMAGE_SIZE,
    },
  },
  finance: {
    en: {
      title: 'Get a Used Car Loan Online | Approved in 24 hours | Carro Hong Kong',
      metaTitle: 'Lowest Used Car Loans | Carro Car Mart Hong Kong',
      description:
        'Finance your used car with Carro’s flexible, low interest car loan options. Get the loan approved for your second-hand car in just 1 day! Find out more now.',
      image: getStaticCDN('/assets/shared/meta-thumb/Carro_Metatag_1200x628.png'),
      ...IMAGE_SIZE,
    },
    'zh-Hant-HK': {
      title: 'Get a Used Car Loan Online | Approved in 24 hours | Carro Hong Kong',
      metaTitle: 'Lowest Used Car Loans | Carro Car Mart Hong Kong',
      description:
        'Finance your used car with Carro’s flexible, low interest car loan options. Get the loan approved for your second-hand car in just 1 day! Find out more now.',
      image: getStaticCDN('/assets/shared/meta-thumb/Carro_Metatag_1200x628.png'),
      ...IMAGE_SIZE,
    },
    'zh-Hans-HK': {
      title: 'Get a Used Car Loan Online | Approved in 24 hours | Carro Hong Kong',
      metaTitle: 'Lowest Used Car Loans | Carro Car Mart Hong Kong',
      description:
        'Finance your used car with Carro’s flexible, low interest car loan options. Get the loan approved for your second-hand car in just 1 day! Find out more now.',
      image: getStaticCDN('/assets/shared/meta-thumb/Carro_Metatag_1200x628.png'),
      ...IMAGE_SIZE,
    },
  },
  error: {
    en: {
      title: 'Error | Carro Hong Kong',
      metaTitle: 'Error | Carro Hong Kong',
    },
    'zh-Hant-HK': {
      title: '錯誤 | Carro 香港',
      metaTitle: '錯誤 | Carro 香港',
    },
    'zh-Hans-HK': {
      title: '错误 | Carro 香港',
      metaTitle: '错误 | Carro 香港',
    },
  },
  termsAndConditions: {
    en: {
      title: 'Terms of Use | Carro Hong Kong',
      metaTitle: 'Terms of Use | Carro Hong Kong',
      description: 'The Best Place to Buy and Sell Second-Hand & Used Cars in Hong Kong',
      image: getStaticCDN('/assets/shared/meta-thumb/Carro_Metatag_1200x628.png'),
      ...IMAGE_SIZE,
    },
    'zh-Hant-HK': {
      title: '使用條款 | Carro 香港',
      metaTitle: '使用條款 | Carro 香港',
      description: '香港買賣二手車的最佳地點',
      image: getStaticCDN('/assets/shared/meta-thumb/Carro_Metatag_1200x628.png'),
      ...IMAGE_SIZE,
    },
    'zh-Hans-HK': {
      title: '使用条款 | Carro 香港',
      metaTitle: '使用条款 | Carro 香港',
      description: '香港买卖二手车的最佳地点',
      image: getStaticCDN('/assets/shared/meta-thumb/Carro_Metatag_1200x628.png'),
      ...IMAGE_SIZE,
    },
  },
};

const META_DATA: Record<TRegionKey, MetaDataProps> = {
  my: MY_META_DATA,
  sg: SG_META_DATA,
  th: TH_META_DATA,
  id: ID_META_DATA,
  jp: JP_META_DATA,
  hk: HK_META_DATA,
};

export default META_DATA;
